import { FC } from 'react';
import DefaultLoader from './Default';
import OptumLoader from './Optum';
import OptumDataCoreLoader from './OptumDataCore';

const loadersMap: Record<string, FC> = {
  optum: OptumLoader,
  optumDataCore: OptumDataCoreLoader,
};

const getLoader = (id?: string): FC => ((id && loadersMap[id]) as FC) ?? DefaultLoader;

export default getLoader;
