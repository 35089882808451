import React from 'react';
import './App.less';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Overlay from './components/Overlay/Overlay';
import WriteBackLoading from './components/WriteBackLoading';

import '@getvim/atomic-ui/assets/styles/main.less';

const App: React.FC = () => (
  <ThemeVariablesWrapper theme={themes.vim}>
    <Router basename={import.meta.env.BASE_URL}>
      <Switch>
        <Route exact path="/overlay">
          <Overlay />
        </Route>
        <Route exact path="/writeBackLoading">
          <WriteBackLoading />
        </Route>
      </Switch>
    </Router>
  </ThemeVariablesWrapper>
);

export default App;
