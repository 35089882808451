import React from 'react';
import { useWidgetHealthCheck } from '../../hooks/useWidgetHealthCheck';
import styles from './Overlay.module.scss';

const Overlay: React.FC = () => {
  useWidgetHealthCheck();
  return <div className={styles.overlay} />;
};

export default Overlay;
