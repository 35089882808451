import React, { FC } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import getLoader from './Loader';
import { useWidgetHealthCheck } from '../../hooks/useWidgetHealthCheck';

const WriteBackLoading: FC = () => {
  useWidgetHealthCheck();
  const { search: queryString } = useLocation();
  const { id } = parse(queryString);
  const LoaderComponent: FC = getLoader(id?.toString());

  return <LoaderComponent />;
};

export default WriteBackLoading;
